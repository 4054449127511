<template>

<app-content-box class="seats-wrapper" :title="title" :subtitle="subtitle" icon="seats" :not-padded="true">

	<div class="seats" :class="{'is-many': seats.total > 8}">

		<div class="seats-item" v-for="(seat, index) in seats.indexed" :key="index">

			<div class="seats-item-avatar" v-bgimage="seat.avatar">

				<app-icon icon="user" v-if="!seat.avatar" />

			</div>

			<div class="seats-item-name">{{ seat.name }}</div>

			<div class="seats-item-host" v-if="seat.type === $constants.schedule.seat.host && !isProposal">Host</div>
			<div class="seats-item-host" v-if="seat.type === $constants.schedule.seat.host && isProposal">Proposer</div>

		</div>

		<template v-if="seats.total">

			<div class="seats-item is-empty" v-for="seat in seats.available" :key="seat + 'b'">

				<div class="seats-item-avatar"><app-icon icon="user" /></div>

				<div class="seats-item-name">Empty seat</div>

			</div>

		</template>

	</div>

</app-content-box>

</template>

<script>

export default {

	props: ['title', 'seats'],

	computed: {

		isProposal: function() {

			return false //this.event.status === this.$constants.eventStatus.proposal

		},

		subtitle: function() {

			return (this.seats.total) ? this.seats.available + ' available' : 'Unlimited'

		}

	}

}

</script>

<style scoped>

.seats-wrapper {
	grid-column: 1 / 5;
}

.seats {
	display: flex;
	flex-wrap: wrap;
	padding: 40px 20px 0px 20px;
}

.is-desktop .seats.is-many,
.is-tablet .seats.is-many {
	max-height: 200px;
	overflow-y: auto;
	padding: 20px;
}

.is-tablet .seats {
	padding: 10px 10px 0px 0px;
}

.is-mobile .seats {
	padding: 0px;
}

.seats-item {
	width: 160px;
	height: 240px;
	margin: 0px 20px;
}

.is-tablet .seats-item {
	width: 120px;
	height: 170px;
	margin: 0px 10px;
}

.is-desktop .seats.is-many .seats-item {
	width: 25%;
	height: 48px;
	margin: 0px;
	padding: 8px 10px;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.is-tablet .seats.is-many .seats-item {
	width: 33.3%;
	height: 48px;
	margin: 0px;
	padding: 8px 10px;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.is-mobile .seats-item {
	width: 100%;
	height: 48px;
	margin: 0px;
	border-bottom: 1px solid #eee;
	padding: 8px 10px;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.is-mobile .seats-item:last-child {
	border-bottom-width: 0px;
}

.seats-item-avatar {
	width: 160px;
	height: 160px;
	background-color: #eee;
	background-position: 50% 50%;
	background-size: cover;
	border-radius: 50%;
	margin-bottom: 20px;
}

.seats.is-many .seats-item-avatar {
	width: 32px;
	height: 32px;
	margin: 0px;
}

.is-tablet .seats-item-avatar {
	height: 120px;
	margin-bottom: 10px;
	width: 120px;
}

.is-mobile .seats-item-avatar {
	width: 32px;
	height: 32px;
	margin: 0px;
}

.seats-item.is-empty .seats-item-avatar {
	background-color: #fff;
	border: 10px dashed #eee;
}

.is-tablet .seats-item.is-empty .seats-item-avatar {
	border-width: 5px;
}

.is-mobile .seats-item.is-empty .seats-item-avatar {
	border-width: 2px;
}

.seats.is-many .seats-item.is-empty .seats-item-avatar {
	border-width: 2px;
}

.seats-item >>> .icon {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 64px;
	color: #999;
}

.seats.is-many .seats-item >>> .icon { 
	font-size: 16px;
}

.is-tablet .seats-item >>> .icon { 
	font-size: 48px;
}

.is-mobile .seats-item >>> .icon { 
	font-size: 16px;
}

.seats-item.is-empty >>> .icon {
	color: #eee;
}

.seats-item-name {
	text-align: center;
	color: #687589;
    font-size: 20px;
    font-weight: 300;
}

.seats.is-many .seats-item-name {
	padding-left: 10px;
	font-size: 16px;
}

.is-tablet .seats-item-name {
	font-size: 16px;
}

.is-mobile .seats-item-name {
	padding-left: 10px;
	font-size: 16px;
}

.seats-item.is-empty .seats-item-name {
	color: #d3d3d3;
}

.seats-item-host {
	font-size: 14px;
	color: #687589;
	margin-top: 2px;
	text-align: center;
}

.is-tablet .seats-item-host {
	font-size: 12px;
}

.is-mobile .seats-item-host {
	font-size: 12px;
	display: block;
	margin-left: 5px;
	opacity: 0.5;
}

</style>
